import {
  AuthenticationContext,
  AuthenticationOptions,
  inject,
  UserContext,
} from "@myob/ldal";
import Config from "config";

let authenticationAdapter: AuthenticationAdapter;

export const LOGIN_ERROR_MESSAGE = "login not work";
export class AuthenticationAdapter {
  authenticationContext: AuthenticationContext;

  constructor(options: AuthenticationOptions) {
    this.authenticationContext = inject(options);
    this.authenticationContext.handleOAuth2Callback();
  }

  getUser(): UserContext | undefined {
    return this.authenticationContext.getUser();
  }

  login(): void {
    this.authenticationContext.logIn();
  }

  logout(pathName = ""): void {
    this.authenticationContext.options.postLogoutRedirectUri += pathName;
    this.authenticationContext.logOut();
  }

  async acquireToken(): Promise<string> {
    return new Promise((resolve) => {
      this.authenticationContext.acquireToken(
        Config.AUTHENTICATION_BFF_CLIENT_ID,
        (token: string, error: boolean) => {
          if (error) {
            this.login();
          } else {
            resolve(token);
          }
        }
      );
    });
  }

  isLoggedIn(): boolean {
    return this.getUser() != null;
  }
}

export default function index(): AuthenticationAdapter {
  if (authenticationAdapter == null) {
    authenticationAdapter = new AuthenticationAdapter({
      authority: Config.AUTHENTICATION_AUTHORITY,
      clientId: Config.AUTHENTICATION_WEB_CLIENT_ID,
      loginResource: Config.AUTHENTICATION_BFF_CLIENT_ID,
      storageLocation: "localStorage",
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
    });
  }
  return authenticationAdapter;
}
