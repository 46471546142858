import "./set-public-path";
import initAuth, { LOGIN_ERROR_MESSAGE } from "./auth";
import initSplitAdapter from "./splitio/splitToggles";
import {
  page,
  segmentTrack,
  track,
  group,
  identify,
  initSegment,
  initBusinessId,
  MOCAGroup,
} from "telemetry";
import { getRegionFromTimezone } from "helpers/tools";
import { push } from "ga";
import TagManager from "./gtm";
import sendReportToGtm from "./webVitals/reportWebVitals";

const adapter = initAuth();
export const auth = {
  LOGIN_ERROR_MESSAGE,
  getUser: () => adapter.getUser(),
  isLoggedIn: () => adapter.isLoggedIn(),
  login: () => adapter.login(),
  getToken: async () => await adapter.acquireToken(),
  logout: (pathName = "") => adapter.logout(pathName),
};

export const telemetry = {
  page,
  segmentTrack,
  track,
  group,
  MOCAGroup,
  identify,
  initSegment,
  initBusinessId,
};

export const ga = {
  push,
};

export const helper = {
  getRegionFromTimezone,
};

const splitIOAdapter = initSplitAdapter();
export const splitToggles = {
  init: async (userIdentifier) => await splitIOAdapter.init(userIdentifier),
  isToggleOn: (splitName) => splitIOAdapter.isToggleOn(splitName),
};

export const checkFeelixVersion = (
  projectName: string,
  projectFeelixVersion: string
) => {
  if (FEELIX_VERSION !== projectFeelixVersion) {
    console.error(
      `[${projectName} ERROR:] PLEASE UPDATE YOUR "@myob/myob-widgets" VERSION TO ${FEELIX_VERSION}`
    );
  }
};

export const GtmManager = {
  dataLayer: (dataLayerArgs) => TagManager.dataLayer(dataLayerArgs),
  initialize: (dataLayerArgs) => TagManager.initialize(dataLayerArgs),
};

export const webVitals = {
  sendReportToGtm,
};
